import React, { useState, useEffect } from 'react'
import './estilos.css'
import { scroller, animateScroll as scroll } from "react-scroll";
import { connect } from 'react-redux'

function Navbar(props) {
    const [opacidad, setOpacidad] = useState(1);
    const [menu, setMenu] = useState(1)
    window.onscroll = () => {
        if (window.pageYOffset >= 200) {
            setOpacidad(0)
        } else {
            setOpacidad(1)
        }
        if (window.pageYOffset >= 1400) {
            setMenu(3)
            setOpacidad(1)
            document.getElementById('down').style.display = "none"
            document.getElementById('up').style.display = "block"
        }
        if (window.pageYOffset <= 200) {
            setMenu(1)
            document.getElementById('down').style.display = "block"
            document.getElementById('up').style.display = "none"
        }
    }
    const handleClick = () => {
        if (menu === 1) {
            scroller.scrollTo(('Section2').toString());
            setMenu(2)
        }
        else if (menu === 2) {
            scroller.scrollTo(('Section3').toString());
            document.getElementById('down').style.display = "none"
            document.getElementById('up').style.display = "block"
        }
        else if (menu === 3) {
            scroll.scrollToTop();

        }

    }
    return (
        <div className="menu">
            <div className="wrapperMenu">
                <a href="https://www.greencardpv.com/" className="greenCard colMenu" style={{ opacity: opacidad }}>
                    <img src="../../images/greencard.png" alt="" />
                </a>
            </div>
        </div>
    )
}

const PropsStore = state => {
    return {
        data: state.dataPlantilla.data
    }
}

export default connect(PropsStore, null)(Navbar)
