import React, { useEffect } from 'react'
import './estilosModal.css'
import {connect} from 'react-redux'
function Modal(props) {
    useEffect(() => {
        console.log(props)
    }, [props.data])
    if (props.modal === true) {

        return (
            <div className="Modal">
                <div className="ModalContent">
                    <button className="buttonClose" onClick={() => props.function(false)}>x</button>
                    <h1 className="titleModal" style={{fontFamily:props.data.fuente_titulo,textAlign:'center'}}>{props.data.nombre}</h1>
                    <article style={{fontFamily:props.data.fuente_modal}} dangerouslySetInnerHTML={{__html: props.data.descripcion_completa}}></article>
                    {/*<div className="sectionButtonsModal">
                        <a className="buttonModal" href={`https://wa.me/${props.Plantilla.Whatsapp}?text=Excelente%20día,%20 vengo%20de%20tu%20*GreenCardPv*%20quiero%20mas%20información%20sobre%20${props.data.nombre}`}>
                            <i className="fab fa-whatsapp"></i> Mas Informacion
                        </a>
                    </div>*/}
                </div>
                <div className="ModalCover" onClick={() => props.function(false)}></div>
            </div >
        )
    } else {
        return (false)
    }
}
const PropsStore = state => {
    return {
        Plantilla:state.dataPlantilla.data
    }
}

export default connect(PropsStore,null)(Modal)