import {createStore} from 'redux'
import Reducers from './reducers/index'

//Para verlo en navegador
import {composeWithDevTools} from 'redux-devtools-extension'

const globalState = localStorage.getItem('GLOBAL_STATE')
const initialState = globalState ? JSON.parse(globalState) : undefined


const store = createStore(Reducers,initialState,composeWithDevTools())

export const saveState = () =>{
    const state = store.getState()
    localStorage.setItem('GLOBAL_STATE',JSON.stringify(state))
}

export default store