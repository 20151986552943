import React,{ useState, useEffect }  from 'react'

import {connect} from 'react-redux'
import Loading from '../components/loading'
import { data_action } from '../redux/actions/dataActions'
function AutoContext(props) {
    const [data, setData] = useState({})
    const [time,setTime] = useState(false)
    useEffect(async () => {
        await contador();
        setTimeout(function(){ setTime(true) }, 2000);
    }, [])
    const contador = () => {
        let formData = new FormData();

        formData.append('id',window.subdominio);
        fetch("https://plataforma.greencardpv.com/api/controller/obtengoDatos.php", {
            method: 'POST',
            body: formData,
            mode: 'cors',

        }).then(response => response.json())
            .then(data => {
                console.log(data)
                props.data_action(data)
                setData(data)
            });
    }
    if (data.TotalSlider && time !== false ) {
        return (
            <>
            {props.children}
            </>
        )
    }else{
        return(
           <Loading/> )
    }
}

const PropsStore = state => {
    return {
        data:state.dataPlantilla.data
    }
}
const functionStore = {
    data_action
}

export default connect(PropsStore,functionStore)(AutoContext)