import {DATA} from '../actions/dataActions'

const default_data={
    data:false,
}

const dataPlantilla = (state=default_data,action)=>{
    switch(action.type){
        case DATA:{
            return{
                ...state,data:action.payload
            }
        }
        default: return state
    }
}

export default dataPlantilla;